import Link from 'next/link';
import clsx from 'clsx';
import { ChevronRightIcon } from '@heroicons/react/outline';

export function FormatSearchResult({ item, active, onClick }) {
  return (
    <div className="ml-4 flex w-full flex-row justify-between">
      <div>
        <p
          className={clsx('text-sm font-medium', active ? 'text-gray-900' : 'text-gray-700')}
          dangerouslySetInnerHTML={{ __html: item._highlightResult.title.value }}
        ></p>
        <p
          className={clsx('min-h-max text-sm', active ? 'text-gray-700' : 'text-gray-500')}
          dangerouslySetInnerHTML={{ __html: item._highlightResult.description.value }}
        ></p>
      </div>
      <div>
        <Link
          href={`/docs/${item.slug}`}
          onClick={() => onClick()}
          className={clsx(
            active ? ' text-yellow-400 ' : ' text-gray-200',
            'my-auto ml-3 mr-1 flex flex-none font-semibold'
          )}
        >
          Przejdź{' '}
          <ChevronRightIcon
            className={clsx(
              active
                ? 'translate-x-2 text-yellow-300 transition duration-700 delay-100 ease-in-out '
                : ' text-gray-200',
              ' mt-1 h-5 w-5'
            )}
          ></ChevronRightIcon>
        </Link>
      </div>
    </div>
  );
}
