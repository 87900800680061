import { TinyWaveFormIcon } from '@/components/common/Icons';
import TextExpander from '@/components/common/TextExpander';

export default function AboutSection({ isOpen }) {
  let text = isOpen
    ? 'Pochwal się swoją półką i kontroluj jej stan. Zostań własnym bibliotekarzem i podglądaj zbiory innych. Poznaj ludzi, którzy czytali to co lubisz. Kataloguj, gromadź książki lepiej i przekrocz kolejny próg bibliofilstwa.'
    : 'Zaoferuj czytelnikom dołączenie do wyjątkowej społeczności. Daj swoim autorom moc bycia w kontakcie ze swoimi czytelnikami. Zwiększ wartość swoich publikacji poprzez stworzenie unikalnych egzemplarzy.';
  return (
    <section className="mt-4">
      <h2 className="flex items-center font-mono text-sm font-medium leading-7 text-slate-900">
        <TinyWaveFormIcon colors={['fill-teal-500', 'fill-blue-300']} className="h-2.5 w-2.5" />
        <span className="ml-2.5">{isOpen ? 'O nas' : 'O platformie'}</span>
      </h2>
      <TextExpander text={text} />
    </section>
  );
}
