import AppContext from '@/AppContext';
import NotificationSimple from '@/components/Notifications/NotificationSimple';
import { useState } from 'react';

const MSG_TEMPLATES = {
  success: {
    title: 'Super!',
    description: 'Akcja wykonana poprawnie.',
    type: 'success',
    timeout: 3000,
  },
  error: {
    title: 'Error!',
    description: 'Spróbuj później lub skontaktuj się z nami.',
    type: 'error',
    timeout: 6000,
  },
  warning: {
    title: 'Ups!',
    description: 'Coś poszło nie tak. Spróbuj ponownie.',
    type: 'warning',
    timeout: 5000,
  },
  info: {
    title: 'Ogłoszenie!',
    description: 'Sprawdź naszego bloga aby dowiedzieć się więcej.',
    type: 'info',
    timeout: 4000,
  },
};

const ALLOWED_STATES = ['success', 'warning', 'error', 'info'];

export function NotificationsProvider({ children }) {
  //eslint-disable-next-line
  const [message, _] = useState({ title: '', description: '', type: '', timeout: 3000 });
  const [isVisible, setIsVisible] = useState(false);

  const setNotification = (msg) => {
    if (typeof msg === 'object' && msg !== null && !Array.isArray(msg)) {
      if (msg && msg.type) {
        message.type = msg.type;
        message.title = MSG_TEMPLATES[msg.type].title;
        message.description = MSG_TEMPLATES[msg.type].description;
      }
      if (msg && msg.title) {
        message.title = msg.title;
      }
      if (msg && msg.description) {
        message.description = msg.description;
      }
      if (msg && msg.timeout) {
        message.timeout = msg.timeout;
      }
    } else {
      if (ALLOWED_STATES.includes(msg)) {
        message.type = MSG_TEMPLATES[msg].type;
        message.title = MSG_TEMPLATES[msg].title;
        message.description = MSG_TEMPLATES[msg].description;
      }
    }

    setIsVisible(true);

    setTimeout(() => {
      setIsVisible(false);
    }, message.timeout);
  };
  return (
    <>
      <AppContext.Provider
        value={{
          state: message,
          setNotification: setNotification,
        }}
      >
        {children}
      </AppContext.Provider>
      <NotificationSimple showNotification={isVisible} msg={message} />
    </>
  );
}
