import { useContext, useEffect } from 'react';
import AppContext from '@/AppContext';
import apiClient from './apiClient';
import { useRefreshToken } from './hooks/useRefreshToken';
import { useSession } from 'next-auth/react';
import formatErrorMessage from '../utils/extractErrorMessages';
import statusCodes from '@/helpers/statusCodes';

const AxiosErrorHandler = ({ children }) => {
  const { data: session } = useSession();
  const context = useContext(AppContext);
  const refreshToken = useRefreshToken();

  useEffect(() => {
    const responseInterceptor = apiClient.interceptors.response.use(
      async (response) => {
        if (response.status !== 200)
          context.setNotification({
            type: 'success',
            title: statusCodes[response.status],
          });
        let { data } = response;
        return data;
      },

      async (error) => {
        const prevRequest = error?.config;
        if (error?.response?.status === 401 && !prevRequest?.sent) {
          prevRequest.sent = true;
          await refreshToken();
          prevRequest.headers['Authorization'] = `Bearer ${session?.access}`;
          return apiClient(prevRequest);
        }
        // return Promise.reject(error);

        // if (error?.response?.status === 401) {
        //   await refreshToken();
        //   apiClient.interceptors.request.use(async (config) => {
        //     if (config.headers && session && session.access)
        //       /* eslint-disable */
        //       (config.headers as AxiosHeaders).set('Authorization', `Bearer ${session.access}`);
        //     /* eslint-enable */
        //     return config;
        //   });
        // }
        if (error && error.response && error.response.data) {
          context.setNotification({
            type: 'warning',
            description: formatErrorMessage(error.response.data),
          });
        }
        (error) => Promise.reject(error);
        // return error;
      }
    );

    return () => {
      apiClient.interceptors.response.eject(responseInterceptor);
      apiClient.interceptors.request.eject(responseInterceptor);
    };
  }, [context, session, refreshToken]);
  return <>{children}</>;
};

export default AxiosErrorHandler;
