import { Popover } from '@headlessui/react';
import { useEffect } from 'react';

export function NoneLayout({ children }) {
  useEffect(() => {
    // document.querySelector('html').classList.add('h-full')
    // document.querySelector('html').classList.add('bg-gray-50')
    // document.body.classList.add('h-full')
    // document.body.classList.add('overflow-hidden')
  });

  return (
    <>
      <main className="px-0">
        <div className="">{children}</div>
      </main>
    </>
  );
}
