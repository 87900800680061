import clsx from 'clsx';
import { useState } from 'react';

export default function TextExpander({ text, charLimit = 150, className = '' }) {
  // rozwiń, expand, trim
  if (!text) {
    text = 'Brak opisu';
  }
  let [isExpanded, setIsExpanded] = useState(text.length < charLimit);
  let [abbr, full] = [text.slice(0, charLimit), text.slice(charLimit, text.length)];
  console.log(full);
  return (
    <>
      {text.length < charLimit ? (
        <p className={clsx('mt-2 text-base leading-7 text-slate-700', className)}>{text}</p>
      ) : (
        <>
          <p
            className={clsx(
              'mt-2 text-base leading-7 text-slate-700',
              !isExpanded && 'lg:line-clamp-4'
            )}
          >
            {abbr} {isExpanded ? full : '...'}
            {full && (
              <button
                type="button"
                className="mt-2 mx-2 text-sm font-bold leading-6 text-yellow-700 hover:text-yellow-800 active:text-yellow-900 lg:inline-block"
                onClick={() => setIsExpanded(!isExpanded)}
              >
                {isExpanded ? 'Zwiń' : 'Czytaj więcej'}
              </button>
            )}
          </p>
        </>
      )}
    </>
  );
}
