import {
  BookOpenIcon,
  ChatAltIcon,
  IdentificationIcon,
  MapIcon,
  MicrophoneIcon,
  PresentationChartBarIcon,
  QrcodeIcon,
} from '@heroicons/react/outline';
import { IoBookSharp } from 'react-icons/io5';

export const MENU_ROUTES = [
  {
    label: 'Książki',
    href: '/dashboard/books',
    icon: BookOpenIcon,
    name: 'books',
    permission: 'all',
    children: [
      { label: 'Posiadane', name: 'owned', href: '/dashboard/books/' },
      { label: 'Niedostępne', name: 'unavailable', href: '/dashboard/books/unavailable' },
      { label: 'Pożyczone', name: 'borrowed', href: '/dashboard/books/borrowed' },
      { label: 'Otrzymane', name: 'recieved', href: '/dashboard/books/recieved' },
    ],
  },
  {
    label: 'Recenzje',
    href: '/dashboard/recordings/mine',
    icon: MicrophoneIcon,
    name: 'recordings',
    permission: 'all',
    children: [
      { label: 'Dodaj', name: 'mine', href: '/dashboard/recordings/add' },
      { label: 'Moje', name: 'mine', href: '/dashboard/recordings/mine' },
      { label: 'Czytelników', name: 'users', href: '/dashboard/recordings/users' },
    ],
  },
  {
    label: 'Półki',
    href: '/dashboard/bookshelves',
    icon: MapIcon,
    name: 'maps',
    permission: 'all',
    children: [
      { label: 'Mapa półek', name: 'map', href: '/dashboard/bookshelves/map' },
      { label: 'Moje półki', name: 'shelves', href: '/dashboard/bookshelves/shelves' },
    ],
  },
  {
    label: 'Etykiety',
    href: '/dashboard/qrs',
    icon: QrcodeIcon,
    name: 'qrs',
    permission: 'all',
  },

  // ------------------
  // Permissions Routes
  // ------------------
  {
    label: 'Publikacje',
    href: '/dashboard/publisher',
    icon: IoBookSharp,
    name: 'publisher',
    permission: 'is_publisher',
  },
  {
    label: 'Wiadomości',
    href: '/dashboard/msgs',
    icon: ChatAltIcon,
    name: 'msgs',
    permission: 'is_superuser',
  },
  {
    label: 'Statystyki',
    href: '/dashboard/stats',
    icon: PresentationChartBarIcon,
    name: 'stats',
    permission: 'is_superuser',
  },
  {
    label: 'Katalog',
    href: '/dashboard/isbn',
    icon: IdentificationIcon,
    name: 'isbn',
    permission: 'is_superuser',
  },
];
