import Header from '@/components/Header';

export function MainLayout({ children, query, setQuery }) {
  return (
    <>
      <Header query={query} setQuery={setQuery}></Header>
      <main className="px-0">
        <div className="pt-20">{children}</div>
      </main>
    </>
  );
}
