import clsx from 'clsx';
import { PauseIcon, PlayIcon } from '../common/Icons';


export function PlayButton({ player, color='teal', size = 'large' }) {
  return (
    <button
      data-umami-event="Play / Pause"
      type="button"
      className={clsx(
        `group relative flex flex-shrink-0 items-center justify-center rounded-full bg-${color}-400 hover:bg-${color}-500 focus:outline-none focus:ring-${color}-300`,
        {
          large: 'h-18 w-18 focus:ring focus:ring-offset-4',
          medium: 'h-14 w-14 focus:ring-2 focus:ring-offset-2',
          small: 'h-10 w-10 focus:ring-2 focus:ring-offset-2',
        }[size]
      )}
      onClick={player.toggle}
      aria-label={player.playing ? 'Pause' : 'Play'}
    >
      <div className="absolute -inset-3 md:hidden" />
      {player.playing ? (
        <PauseIcon
          className={clsx(
            'fill-white group-active:fill-white/80',
            {
              large: 'h-7 w-7',
              medium: 'h-5 w-5',
              small: 'h-4 w-4',
            }[size]
          )}
        />
      ) : (
        <PlayIcon
          className={clsx(
            'fill-white group-active:fill-white/80',
            {
              large: 'h-9 w-9',
              medium: 'h-7 w-7',
              small: 'h-5 w-5',
            }[size]
          )}
        />
      )}
    </button>
  );
}
