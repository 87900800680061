import BASE_API_URL from '@/helpers/baseUrls';
import axios from 'axios';
import { getSession } from 'next-auth/react';

// Axios response schema:
// {
//   data: {},
//   status: 200,
//   statusText: 'OK',
//   headers: {},
//   config: {},
//   request: {}
// }

const ApiClient = () => {
  const instance = axios.create({
    baseURL: BASE_API_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json', // can be changed in interceptors located in network functions
    },
  });

  instance.interceptors.request.use(
    async (config) => {
      const session = await getSession();
      if (config.headers && session && session.access)
        /* eslint-disable */
        (config.headers as AxiosHeaders).set('Authorization', `Bearer ${session.access}`);
      /* eslint-enable */
      return config;
    },
    (error) => Promise.reject(error)
  );

  // Response interceptor is in apiErrorHandler.tsx

  return instance;
};

export default ApiClient();
