// import Header from '@/components/Header'
import TextLink from '@/components/Links/TextLink';
import { LockOpenIcon } from '@/components/common/Icons';

import { AudioPlayer } from '@/components/player/AudioPlayer';

export function VaultLayout({ children }) {
  return (
    <>
      {/* <Header></Header> */}
      <main className="mx-auto border-t border-slate-200 bg-white lg:relative lg:mb-28 lg:w-1/3">
        <div className="flex justify-center px-10 pb-6 pt-10 text-center">
          <LockOpenIcon className="mx-2 h-5 w-5 font-bold text-yellow-500" />
          <TextLink href="/">Wyjdź z Sejfu</TextLink>
        </div>

        <div className="relative mx-auto">{children}</div>
      </main>
      <div className="fixed inset-x-0 bottom-0 z-10 lg:left-112 xl:left-120">
        <AudioPlayer />
      </div>
    </>
  );
}
