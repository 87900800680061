import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import qs from 'qs';
import { useState } from 'react';

import { AudioProvider } from '@/components/ListenPage/AudioProvider';
import siteMetadata from '@/data/siteMetadata';
import { AudioLayout } from '@/layouts/AudioLayout';
import { PlayerLayout } from '@/layouts/PlayerLayout';
import { AuthLayout } from '@/layouts/AuthLayout';
import { DashboardLayout } from '@/layouts/DashboardLayout';
import { DocsLayout } from '@/layouts/DocsLayout';
import { MainLayout } from '@/layouts/MainLayout';
import { NoneLayout } from '@/layouts/NoneLayout';
import { RoadmapLayout } from '@/layouts/RoadmapLayout';
import { VaultLayout } from '@/layouts/VaultLayout';
import { SessionProvider } from 'next-auth/react';
import { Analytics } from 'pliny/analytics';
import { QueryClient, QueryClientProvider } from 'react-query';

import { cabinetGrotesk, inter, satoshi } from '../helpers/fonts';
// If loading a variable font, you don't need to specify the font weight

import '@/styles/custom/markdown-editor.css';
import '@/styles/docs/main.css';
import '@/styles/globals.css';
import 'focus-visible';

import Loader from '@/components/common/Loader';
import { NotificationsProvider } from '@/NotificationsProvider';

import { SEOHeader } from '@/components/Headers';
import collectHeadings from '@/utils/docs';
import { GoogleAnalytics } from 'nextjs-google-analytics';
import { NextUIProvider } from '@nextui-org/react'

import AxiosErrorHandler from '@/network/AxiosErrorHandler';
// Create a client
const queryClient = new QueryClient();
const PAGES_BUILD_WITH_MD_FILES = ['roadmap', 'Roadmap'];

const layouts = {
  Audio: AudioLayout,
  Auth: AuthLayout,
  Dashboard: DashboardLayout,
  Docs: DocsLayout,
  Main: MainLayout,
  None: NoneLayout,
  Vault: VaultLayout,
  Roadmap: RoadmapLayout,
  Player: PlayerLayout,
};

export default function App({ Component, pageProps: { session, ...pageProps } }) {
  const router = useRouter();
  let q = qs.parse(router.asPath.split(/\?/)[1]);

  const [query, setQuery] = useState(q.q ? `${q.q}` : '');

  const updateRouteQuery = (event) => {
    // Create share URL only in on main page
    if (router.pathname === '/' && event !== '?') {
      let query = event ? `q=${event}` : '';
      query &&
        router.replace({
          query,
        });
    }
    setQuery(event);
  };

  let pageLayout = Component?.layout ? Component.layout : 'Docs';
  // Docs layout has index.md instread of .js - is there w way to name the layout there?
  const isMarkdocComponent = Component?.name === 'MarkdocComponent';

  if (!Component?.layout) {
    // Add layout property in frontMatter index.md tcapitalizeFirstLettercapitalizeFirstLettero define Layout
    let docTypeComponent = pageProps?.markdoc?.file?.path?.split('/').filter((path) => path)[0];
    // let isIndexFile = pageProps?.markdoc?.file?.path?.includes('index')
    if (docTypeComponent !== undefined && PAGES_BUILD_WITH_MD_FILES.includes(docTypeComponent)) {
      // Object.assign(Component, { layout }); // might be causeing an error on entry
      pageLayout = 'Roadmap';
    }
  }

  const Layout = layouts[pageLayout] || ((children) => <>{children}</>);

  let title = pageProps.markdoc?.frontmatter.title;
  let description = pageProps.markdoc?.frontmatter.description;

  let indexOfDocsTutorials = pageProps.markdoc?.content
    ? collectHeadings(pageProps.markdoc.content)
    : [];

  return (
    <>
      <NextUIProvider>
        {isMarkdocComponent && <SEOHeader title={title} description={description} />}
        <main
          className={`inter ${inter.className} font-sans ${cabinetGrotesk.className} font-display ${satoshi.className}`}
        >
          <GoogleAnalytics trackPageViews />
          <Analytics analyticsConfig={siteMetadata.analytics} />
          <NotificationsProvider>
            <SessionProvider session={session} refetchInterval={60 * 60} refetchOnWindowFocus={true}>
              <AxiosErrorHandler>
                <QueryClientProvider client={queryClient}>
                  <AudioProvider>
                    <Layout
                      tableOfContents={indexOfDocsTutorials}
                      query={query}
                      setQuery={updateRouteQuery}
                    >
                      {Component.auth ? (
                        <Authorize>
                          <Component {...pageProps} />
                        </Authorize>
                      ) : (
                        <Component {...pageProps} />
                      )}
                    </Layout>
                  </AudioProvider>
                </QueryClientProvider>
              </AxiosErrorHandler>
            </SessionProvider>
          </NotificationsProvider>
        </main>
      </NextUIProvider>
    </>
  );
}

function Authorize({ children }) {
  //https://next-auth.js.org/getting-started/client#custom-client-session-handling
  // if `{ required: true }` is supplied, `status` can only be "loading" or "authenticated"
  const { status } = useSession({ required: true });

  if (status === 'loading') {
    return (
      <div className="mt-10 w-full py-20 text-center">
        <Loader></Loader>
      </div>
    );
  }

  return children;
}
