import { FormattedDate } from '@/components/ListenPage/FormattedDate';
import { CameraIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { SquaresPlus } from '../../common/Icons';

export function Navigation({ navigation, className }) {
  let router = useRouter();

  return (
    <nav className={clsx('text-base lg:text-sm ', className)}>
      <ol className="relative border-l border-gray-200 dark:border-gray-700 ">
        {navigation.map((section) => (
          <li key={section.version} className="mb-10 ml-6">
            <span className=" absolute -left-3  flex h-6 w-6 items-center justify-center rounded-full bg-blue-100 ring-8 ring-white dark:bg-blue-900 dark:ring-gray-900">
              <SquaresPlus className="h-5 w-5 fill-blue-500"></SquaresPlus>
            </span>
            <Link
              href={section.href}
              className={clsx(
                'block w-full pl-3.5 before:pointer-events-none before:absolute before:-left-1 before:top-1/2 before:h-1.5 before:w-1.5 before:-translate-y-1/2 before:rounded-full',
                section.href === router.pathname
                  ? 'font-semibold text-sky-500 before:bg-sky-500'
                  : 'text-slate-500 before:hidden before:bg-slate-300 hover:text-slate-600 hover:before:block dark:text-slate-400 dark:before:bg-slate-700 dark:hover:text-slate-300'
              )}
            >
              <div className="mb-1 flex flex-wrap font-semibold text-gray-900 dark:text-white">
                <h3 className="items-center text-lg">
                  {`${section.title}`}
                  <span
                    className={`mr-2 ml-3 rounded bg-${section.type.color}-100 px-2.5 py-0.5 text-sm font-medium text-${section.type.color}-800 dark:bg-${section.type.color}-900 dark:text-${section.type.color}-300`}
                  >
                    {section.type.name}
                  </span>
                 {section?.version && <span
                    className={`inline-block mx-1 rounded bg-indigo-100 px-1.5 py-0.5 text-sm font-medium text-indigo-800 dark:bg-indigo-900 dark:text-indigo-300`}
                  >
                    {`v${section.version}`}
                  </span>}
                </h3>
              </div>
            </Link>
            <time className="mb-2 block text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
              Opublikowana <FormattedDate date={section.release_date}></FormattedDate>
            </time>
            <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
              {section.description}
            </p>
            {section.link && (
              <Link
                rel="noreferrer"
                href={section.link}
                target="_blank"
                className="inline-flex items-center rounded-lg border border-gray-200 bg-white px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:text-blue-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700"
              >
                <CameraIcon className="h-4 w-4 text-blue-500"></CameraIcon>
                Obejrzyj
              </Link>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
