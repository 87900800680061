import userKeyPress from '@/helpers/userKeyPress';
import { Combobox, Dialog, Transition } from '@headlessui/react';
import {
  ChevronRightIcon,
  QuestionMarkCircleIcon,
  ReceiptRefundIcon,
  SearchIcon,
  XIcon,
} from '@heroicons/react/outline';
import clsx from 'clsx';
import Link from 'next/link';
import { Fragment, useEffect, useState } from 'react';
import BookCoverOnBg from './Images/BookCoverOnBg';
import Loader from './Loader';

export default function CommandPallette({ show, query, setShow, handleChange }) {
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchFinished, setSearchFinished] = useState(false);
  const [ctrlPressed, ctrlReleased] = userKeyPress('Control');

  const afterleave = () => {
    setSearchResults([]);
    handleChange('');
    setSearchFinished(false);
    setSearchResults([]);
  };

  useEffect(() => {
    // Debounce bounce effect
    if (query && query !== undefined && query.length > 3 && !query.startsWith('?')) {
      setSearchResults([]);
      const getData = setTimeout(() => {
        setIsSearching(true);
        setSearchFinished(false);
        fetch(`${process.env.NEXT_PUBLIC_DJANGO_URL}/api/bookle/search?q=${query}`)
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            setSearchResults(data.results);
            setIsSearching(false);
            setSearchFinished(true);
          });
      }, 800);
      // Destroy the instance of the useEffect hook using return, followed by clearTimeout, every time it finishes.
      return () => clearTimeout(getData);
    }
    if (query && query.startsWith('?')) {
      setSearchFinished(false);
      setSearchResults([]);
    }
  }, [query]);

  return (
    <Transition.Root show={show} as={Fragment} afterLeave={afterleave} appear>
      <Dialog as="div" className="relative z-30" onClose={() => setShow(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-50"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-50"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="shadow-2xl mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox>
                <div className="relative">
                  <SearchIcon
                    className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <Combobox.Input
                    id="overview-search"
                    role="toolbar"
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
                    placeholder="Znajdź książkę po tytule, ISBN lub autorze."
                    onChange={(e) => handleChange(e.target.value)}
                    value={query}
                  />

                  <div
                    onClick={() => setShow(false)}
                    className="pointer-events-auto absolute inset-y-0 right-0 z-10 flex items-center pr-3"
                  >
                    <XIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                </div>

                {searchResults.length > 0 && !isSearching && (
                  <Combobox.Options static className="max-h-96 scroll-py-3 overflow-y-auto p-3">
                    {searchResults.map((item) => (
                      <Combobox.Option
                        id={`book-option-${item.id}`}
                        key={item.id}
                        role="feed"
                        className={({ active }) =>
                          clsx(
                            'flex cursor-default select-none rounded-xl p-3',
                            active && 'bg-gray-100'
                          )
                        }
                      >
                        {({ active }) => (
                          <>
                            <BookCoverOnBg item={item}></BookCoverOnBg>
                            <div className="ml-4 flex-auto">
                              <p
                                className={clsx(
                                  'text-sm font-medium',
                                  active ? 'text-gray-900' : 'text-gray-700'
                                )}
                              >
                                {item.title}
                              </p>
                              <p
                                className={clsx(
                                  'min-h-max text-sm',
                                  active ? 'text-gray-700' : 'text-gray-500'
                                )}
                              >
                                {item.description && item.description.slice(0, 90) + '...'}
                              </p>
                            </div>
                            <Link
                              href={`/book/${item.slug}`}
                              onClick={() => handleChange('')}
                              className={clsx(
                                active ? ' text-yellow-500 ' : ' text-gray-200',
                                'my-auto ml-3 mr-1 flex flex-none font-semibold'
                              )}
                            >
                              Pokaż{' '}
                              <ChevronRightIcon
                                className={clsx(
                                  active
                                    ? 'translate-x-2 text-yellow-400 transition duration-700 delay-100 ease-in-out '
                                    : ' text-gray-200',
                                  ' mt-1 h-5 w-5'
                                )}
                              ></ChevronRightIcon>
                            </Link>
                          </>
                        )}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                )}

                {query && query.startsWith('?') && !isSearching && (
                  <div className="py-14 px-6 text-center text-sm sm:px-14">
                    <QuestionMarkCircleIcon
                      type="outline"
                      name="exclamation-circle"
                      className="mx-auto h-6 w-6 text-gray-400"
                    />
                    <p className="mt-4 font-semibold text-gray-900">Pomoc nadchodzi!</p>
                    <p className="mt-2 text-gray-500">
                      Aby dowiedzieć się więcej, zapoznaj się z{' '}
                      <Link className="text-md text-bold text-teal-400" href={'/docs'}>
                        poradnikiem
                      </Link>{' '}
                      .{' '}
                    </p>
                  </div>
                )}

                {query && !query.startsWith('?') && query !== '' && isSearching && (
                  <div className="h-full py-14 px-6 text-center text-sm sm:px-14">
                    <Loader></Loader>
                  </div>
                )}
                {query !== '' && searchResults.length === 0 && !isSearching && searchFinished && (
                  <div className="py-14 px-6 text-center text-sm sm:px-14">
                    <ReceiptRefundIcon
                      type="outline"
                      name="exclamation-circle"
                      className="mx-auto h-6 w-6 text-gray-400"
                    />
                    <p className="mt-4 font-semibold text-gray-900">Nie mamy takiej książki?</p>
                    <p className="mt-2 text-gray-500">
                      Nie znaleziono żadnej książki z takim słowem kluczowym. Spróbuj wpisać
                      inaczej.
                    </p>
                  </div>
                )}
                <div className="flex flex-wrap items-center bg-gray-50 py-2.5 px-4 text-xs text-gray-700">
                  Kliknij{' '}
                  <kbd
                    className={clsx(
                      'mx-1 flex h-5 w-11 items-center justify-center rounded border bg-white font-semibold sm:mx-2',
                      ctrlPressed
                        ? 'border-rose-600 text-rose-600'
                        : 'border-gray-400 text-gray-900'
                    )}
                  >
                    Ctrl
                  </kbd>{' '}
                  <span className="sm:hidden">aby zamknąć okno,</span>
                  <span className="hidden sm:inline">aby zamknąć okno,</span>
                  <span className="ml-1">wpisz</span>
                  <kbd
                    className={clsx(
                      'mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white font-semibold sm:mx-2',
                      query && query.startsWith('?') === '?'
                        ? 'border-blue-600 text-blue-600'
                        : 'border-gray-400 text-gray-900'
                    )}
                  >
                    ?
                  </kbd>{' '}
                  aby pokazać pomoc.
                </div>
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
