const currentDate = new Date().toISOString();
const HELP_ROUTES = [
  {
    title: 'Wstęp',
    links: [{ title: 'Zaczynamy', href: '/docs', help: 'intro', updated_at: currentDate }],
  },
  {
    title: 'Katalogowanie',
    links: [
      {
        title: 'Katalogowanie książek',
        href: '/docs/katalogowanie-ksiazek',
        help: 'cl-books',
        updated_at: currentDate,
      },
      {
        title: 'Etykiety z Kodami',
        href: '/docs/katalogowanie-etykiety-zestawy-kodow-kreskowych',
        help: 'cl-codes',
        updated_at: currentDate,
      },
      {
        title: 'Katalogowanie telefonem',
        href: '/docs/katalogowanie-telefonem-smartfonem-qr-czytnikiem-isbn',
        help: 'cl-phone',
        updated_at: currentDate,
      },
    ],
  },
  {
    title: 'Bibliodesk',
    links: [
      {
        title: 'Mapa półek',
        href: '/docs/bibliodesk-polki-zakladanie-mapa-ustawienia',
        help: 'bb-shelves',
        updated_at: currentDate,
      },
      {
        title: 'Wszystkie Książki',
        href: '/docs/bibliodesk-egzemplarze-ksiazek-uzytkownika',
        help: 'bb-books',
        updated_at: currentDate,
      },
      {
        title: 'Pożyczanie Książek',
        href: '/docs/bibliodesk-nasze-ksiazki-na-polkach-u-innych',
        help: 'bb-borrowed',
        updated_at: currentDate,
      },
      {
        title: 'Przypomnienia',
        href: '/docs/bibliodesk-ustawianie-przypomnien-zwrotu-ksiazek',
        help: 'bb-reminders',
        updated_at: currentDate,
      },
    ],
  },
  {
    title: 'Słowniczek',
    links: [
      {
        title: 'Pojęcia',
        href: '/docs/slownik-pojec',
        help: 'dict',
        updated_at: currentDate,
      },
    ],
  },
];

export default HELP_ROUTES;
