import { ArrowRightIcon } from '@heroicons/react/outline';
import Link from 'next/link';

export default function TextLink({ children, text, url, href }) {
  return (
    <>
      <Link
        href={url || href}
        className="inline-flex items-center font-bold text-gray-500 hover:underline dark:text-blue-500"
      >
        {children || text}
        <ArrowRightIcon className="ml-3 h-3 w-3 text-gray-500"></ArrowRightIcon>
      </Link>
    </>
  );
}
