import Header from '@/components/Header';
import { useState } from 'react';

import { AudioPlayer } from '@/components/player/AudioPlayer';
import { useRouter } from 'next/router';

import AboutSection from '@/components/common/AboutSection';
import clsx from 'clsx';
import { useSearchParams } from 'next/navigation';

export function PlayerLayout({ query, setQuery, bookisbn, children }) {

  const searchParams = useSearchParams();
  let startAt = searchParams.get('t')

  const [isReaderSelected, setIsReaderSelected] = useState(true);
  const router = useRouter();

  const isEpisodeOpened = router.query.episode;

  return (
    <>
      {children}
      <div
        className={clsx(
          'fixed inset-x-0 bottom-0 z-10',
          !isEpisodeOpened && 'lg:left-112 xl:left-120'
        )}
      >
        <AudioPlayer startAt={startAt} />
      </div>
    </>
  );
}
