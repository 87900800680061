const globalMeta = {
  siteName: 'YouTalky.com',
  siteSubtitle: 'Znajdź innych bibliofili i kataloguj książki.',
  description:
    'Poznaj sieć bibliofili i skataloguj swój zbiór. Sprawdź na mapie, gdzie są książki Twoich znajomych.',
  siteUrl: 'https://www.youtalky.com',
  siteLogo: 'https://youtalky.com/logo_youtalky_com.webp',
  ogImg: 'https://youtalky.com/logo_youtalky_com_3d.webp',
  email: 'hello@youtalky.com',
  type: 'website',
};

export default globalMeta;
