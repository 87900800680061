'use client';

import BASE_API_URL from '@/helpers/baseUrls';
import axios from 'axios';
import { signOut, signIn, useSession } from 'next-auth/react';

export const useRefreshToken = () => {
  const { data: session, update } = useSession();

  const refreshToken = async () => {
    try {
      const res = await axios.post(`${BASE_API_URL}/api/auth/jwt/refresh/`, {
        refresh: session?.refresh,
      });
      let access = res.data.access;
      if (session) session.access = access;
      else signIn();
      // https://next-auth.js.org/getting-started/client#updating-the-session
      await update();
      return access;
    } catch (error) {
      if (error?.response?.status === 401) {
        await signOut({ callbackUrl: `${process.env.NEXT_PUBLIC_NEXT_URL}/auth/login` });
      }
    }
  };
  return refreshToken;
};
