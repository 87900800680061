import Head from 'next/head';
import globalMeta from '../../utils/GlobalMetadata';

const SEOHeader = ({
  title = globalMeta.siteName,
  description = globalMeta.description,
  url = globalMeta.siteUrl,
  ogType = globalMeta.type,
  ogTitle = globalMeta.siteName,
  img = '', // static from public folder
  imgUrl = '',
  imgAlt = '',
  slug = '',
  keywords = '',
}) => {
  let uri = slug ? `${globalMeta.siteUrl}${slug}` : url ? url : globalMeta.siteUrl;
  let image = img ? img : imgUrl ? imgUrl : globalMeta.ogImg;
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
        {keywords && <meta name="keywords" content={keywords} />}
        {/* Open graph meta tags. */}
        <meta property="og:title" content={ogTitle} />
        <meta property="og:locale" content="pl_PL" />
        <meta property="og:site_name" content={globalMeta.siteName} />
        <meta property="og:type" content={ogType} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:url" content={uri} />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content={globalMeta.siteName} />
        <meta property="twitter:url" content={url} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
        {imgAlt && <meta name="twitter:image:alt" content={imgAlt} />}
      </Head>
    </>
  );
};

export default SEOHeader;
