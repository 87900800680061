import extractStrings from "@/helpers/extractStrings";

function formatErrorMessage(obj) {
  let msg = extractStrings(obj)
  let errorMsg = msg.join(' ').slice(0, 200)
  return errorMsg;
}


export default formatErrorMessage;
