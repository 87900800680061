import NarrowSidebar from '@/components/Layouts/Dashboard/NarrowSidebar';
import { useEffect } from 'react';

export function DashboardLayout({ children }) {
  useEffect(() => {
    document.querySelector('html').classList.add('h-full');
    document.querySelector('html').classList.add('bg-gray-50');

    document.body.classList.add('h-full');
    document.body.classList.add('overflow-y-auto');
  });

  return (
    <>
      <main className="flex h-full overflow-y-auto">
        <NarrowSidebar></NarrowSidebar>
        <div className="flex flex-1 flex-col overflow-y-auto bg-gray-50">{children}</div>
      </main>
    </>
  );
}
