import logoSquare from '@/assets/images/logo_youtalky_com_white.png';
import { Disclosure } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { useSession } from 'next-auth/react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { MENU_ROUTES } from './routes';

const NarrowSidebar = () => {
  const router = useRouter();
  let path = router.route.split('/')[router.route.split('/').length - 1];

  const { data: session } = useSession();

  let permissions = [];
  if (session) {
    for (const [key, value] of Object.entries(session.user.permissions)) {
      if (value) permissions.push(key);
    }
  }

  return (
    <>
      <div className="hidden w-40 overflow-y-auto bg-teal-700 md:block">
        <div className="flex w-full flex-col items-center py-6">
          <div className="flex flex-shrink-0 items-center">
            <Link href="/dashboard">
              <Image
                className="mx-auto h-9 w-9 opacity-90"
                src={logoSquare}
                alt="Logo Square"
                height={45}
                width={45}
              />
            </Link>
          </div>
          <div className="mt-6 w-full flex-1 space-y-1 px-2">
            {MENU_ROUTES.map((item) =>
              item.permission === 'all' || permissions.includes(item.permission) ? (
                !item.children ? (
                  <div key={item.name}>
                    <Link
                      key={item.name}
                      href={item.href}
                      className={clsx(
                        item.name === path
                          ? 'bg-teal-500 text-teal-50'
                          : 'bg-teal-700 text-white hover:bg-gray-50 hover:text-gray-900',
                        'group flex w-full items-center rounded-md py-2 pl-2 text-sm font-medium'
                      )}
                    >
                      <item.icon
                        className={clsx(
                          item.name === path
                            ? 'text-teal-50'
                            : 'text-white group-hover:text-gray-500',
                          'mr-3 h-6 w-6 flex-shrink-0'
                        )}
                        aria-hidden="true"
                      />
                      {item.label}
                    </Link>
                  </div>
                ) : (
                  <Disclosure as="div" key={item.name} className="space-y-1">
                    {({ open }) => (
                      <>
                        <Disclosure.Button
                          key={item.name}
                          className={clsx(
                            item.current
                              ? 'bg-gray-100 text-gray-900'
                              : 'bg-teal-700 text-white hover:bg-gray-50 hover:text-gray-800',
                            'group flex w-full items-center rounded-md py-2 pl-2 pr-1 text-left text-sm font-medium focus:outline-none focus:ring-2 focus:ring-indigo-500'
                          )}
                        >
                          <item.icon
                            className="mr-3 h-6 w-6 flex-shrink-0 text-teal-50 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                          <span className="flex-1">{item.label}</span>
                          <ChevronRightIcon
                            className={clsx(
                              open ? 'rotate-90 text-white' : 'text-teal-100',
                              'ml-max h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400'
                            )}
                          ></ChevronRightIcon>
                        </Disclosure.Button>
                        <Disclosure.Panel className="space-y-1">
                          {item.children.map((subItem) => (
                            <Disclosure.Button
                              key={subItem.name}
                              as="a"
                              href={subItem.href}
                              className="group flex w-full items-center rounded-md py-2 pl-11 pr-2 text-sm font-medium text-teal-100 hover:bg-gray-50 hover:text-gray-900"
                            >
                              {subItem.label}
                            </Disclosure.Button>
                          ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                )
              ) : null
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NarrowSidebar;
