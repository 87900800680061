const VERSIONS = [
  {
    title: 'Dodawanie w Bibliodesku',
    description: 'Szybsze dodawanie książek.',
    version: '3.4.0',
    href: '/roadmap/v340',
    link: '',
    updated_at: '2023-10-03T12:00:00.000Z',
    release_date: '2023-12-23T12:00:00.000Z',
    type: {
      name: 'Planowana',
      color: 'rose',
    },
  },
  {
    title: 'Recenzje',
    description: 'Koniec z gwiazkami. Recenzje z prawdziwego zdarzenia.',
    version: '3.3.0',
    href: '/roadmap/v330',
    link: '',
    updated_at: '2023-06-30T10:02:00.000Z',
    release_date: '2023-10-03T12:00:00.000Z',
    type: {
      name: 'Obecna',
      color: 'green',
    },
  },
  {
    title: 'Bibliodesk',
    description: 'Wprowadzenie panelu do sterowania własnym zbiorem.',
    version: '3.2.0',
    href: '/roadmap/v322',
    link: '',
    updated_at: '2023-06-30T10:02:00.000Z',
    release_date: '2023-05-21T10:02:00.000Z',
    type: {
      name: 'Wdrożone',
      color: 'blue',
    },
  },
  {
    title: 'Początek śledzenia zmian',
    description: 'Zmiany wprowadzone do tej pory.',
    version: '0.0.0',
    href: '/roadmap/v000',
    link: '',
    updated_at: '2023-06-30T10:02:00.000Z',
    release_date: '2021-07-24T10:02:00.000Z',
    type: {
      name: 'Wdrożone',
      color: 'blue',
    },
  },
  {
    title: 'Strona główna',
    description: '',
    version: '',
    href: '/roadmap',
    link: '',
    updated_at: '2023-05-30T10:02:00.000Z',
    release_date: '2021-06-24T10:02:00.000Z',
    type: {
      name: '',
      color: 'gray',
    },
  },
];

export default VERSIONS;
