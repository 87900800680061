import { Hero } from '@/components/common/Hero';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';

import Header from '@/components/Header';
import { Prose } from '@/components/Pages/Docs/Prose';
import { Navigation } from '@/components/Pages/Roadmap/Navigation';

import VERSIONS from '@/utils/Versions';

let heroProps = {
  header: 'Zmieniamy się razem z Tobą.',
  lead: 'Większość wprowadzanych zmian to funkcjonalności zgłoszone przez czytelników. Co pojawi się na portalu już niedługo?',
  btnOne: {
    text: 'Zgłoś własną',
    link: '/contact',
  },
  btnTwo: {
    text: 'Zaplanowane',
    link: 'https://trello.com/b/V7uvMTxl/youtalkys-software-development',
  },
  video: 'https://www.youtube.com/embed/vTVQUjQoPlU?si=wFw_0bis1kTJ7Bec',
};

export function RoadmapLayout({ children, title, tableOfContents }) {
  let router = useRouter();
  let isHomePage = router.pathname === '/roadmap';
  let allLinks = VERSIONS.flatMap((section) => section.href);
  let linkIndex = allLinks.findIndex((link) => link.href === router.pathname);
  let previousPage = VERSIONS[linkIndex - 1];
  let nextPage = VERSIONS[linkIndex + 1];
  let section = VERSIONS.find((section) => section.href === router.pathname);

  function isActive(section) {
    if (section) {
      return true;
    }
    return false;
  }

  return (
    <>
      <Header navigation={VERSIONS} />

      {isHomePage && <Hero {...heroProps} />}

      <div
        className={clsx(
          isHomePage ? '' : ' pt-12',
          'relative mx-auto flex max-w-8xl justify-center sm:px-2 lg:px-8 xl:px-12'
        )}
      >
        <div className="hidden lg:relative lg:block lg:flex-none">
          <div className="absolute inset-y-0 right-0 w-[50vw] bg-slate-50 dark:hidden" />
          <div className="absolute bottom-0 right-0 top-16 hidden h-12 w-px bg-gradient-to-t from-slate-800 dark:block" />
          <div className="absolute bottom-0 right-0 top-28 hidden w-px bg-slate-800 dark:block" />
          <div className="sticky top-[4.5rem] -ml-0.5 h-[calc(100vh-4.5rem)] overflow-y-auto overflow-x-hidden py-16 pl-5 xl:pl-8">
            <Navigation navigation={VERSIONS} className="w-64 pr-8 xl:w-72 xl:pr-10" />
          </div>
        </div>
        <div className="min-w-0 max-w-2xl flex-auto px-4 py-16 lg:max-w-none lg:pl-8 lg:pr-0 xl:px-16">
          <article>
            {(title || section) && (
              <header className="mb-9 space-y-1">
                {section && (
                  <p className="font-display text-sm font-medium text-sky-500">{section.title}</p>
                )}
                {title && (
                  <h1 className="font-display text-3xl tracking-tight text-slate-900 dark:text-white">
                    {title}
                  </h1>
                )}
              </header>
            )}
            <Prose>{children}</Prose>
          </article>
          <dl className="mt-12 flex border-t border-slate-200 pt-6 dark:border-slate-800">
            {previousPage && (
              <div>
                <dt className="font-display text-sm font-medium text-slate-900 dark:text-white">
                  Poprzednia
                </dt>
                <dd className="mt-1">
                  <Link
                    href={previousPage.href}
                    className="text-base font-semibold text-slate-500 hover:text-slate-600 dark:text-slate-400 dark:hover:text-slate-300"
                  >
                    <span aria-hidden="true">&larr;</span> {previousPage.title}
                  </Link>
                </dd>
              </div>
            )}
            {nextPage && (
              <div className="ml-auto text-right">
                <dt className="font-display text-sm font-medium text-slate-900 dark:text-white">
                  Następna
                </dt>
                <dd className="mt-1">
                  <Link
                    href={nextPage.href}
                    className="text-base font-semibold text-slate-500 hover:text-slate-600 dark:text-slate-400 dark:hover:text-slate-300"
                  >
                    {nextPage.title} <span aria-hidden="true">&rarr;</span>
                  </Link>
                </dd>
              </div>
            )}
          </dl>
        </div>
        <div className="hidden xl:sticky xl:top-[4.5rem] xl:-mr-6 xl:block xl:h-[calc(100vh-4.5rem)] xl:flex-none xl:overflow-y-auto xl:py-16 xl:pr-6">
          <nav aria-labelledby="on-this-page-title" className="w-56">
            {tableOfContents.length > 0 && (
              <>
                <h2
                  id="on-this-page-title"
                  className="font-display text-sm font-medium text-slate-900 dark:text-white"
                >
                  Na tej stronie
                </h2>
                <ol role="list" className="mt-4 space-y-3 text-sm">
                  {tableOfContents.map((section) => (
                    <li key={section.id}>
                      <h3>
                        <Link
                          href={`#${section.id}`}
                          className={clsx(
                            isActive(section)
                              ? 'text-sky-500'
                              : 'font-normal text-slate-500 hover:text-slate-700 dark:text-slate-400 dark:hover:text-slate-300'
                          )}
                        >
                          {section.title}
                        </Link>
                      </h3>
                      {section.children.length > 0 && (
                        <ol
                          role="list"
                          className="mt-2 space-y-3 pl-5 text-slate-500 dark:text-slate-400"
                        >
                          {section.children.map((subSection) => (
                            <li key={subSection.id}>
                              <Link
                                href={`#${subSection.id}`}
                                className={
                                  isActive(subSection)
                                    ? 'text-sky-500'
                                    : 'hover:text-slate-600 dark:hover:text-slate-300'
                                }
                              >
                                {subSection.title}
                              </Link>
                            </li>
                          ))}
                        </ol>
                      )}
                    </li>
                  ))}
                </ol>
              </>
            )}
          </nav>
        </div>
      </div>
    </>
  );
}
