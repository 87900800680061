import clsx from 'clsx';
import Image from 'next/image';

export default function BookCoverOnBg({ item }) {
  return (
    <div
      className={clsx(
        'flex h-8 w-10 flex-none items-center justify-center rounded-lg',
        'bg-yellow-300'
      )}
    >
      {
        <Image
          className="h-11 w-8 rounded"
          width={32}
          height={40}
          src={
            item.thumbnailTiny
              ? item.thumbnailTiny
              : item.thumbnailSmall
              ? item.thumbnailSmall
              : item.thumbnailMedium
              ? item.thumbnailMedium
              : item.smallThumbnail
              ? item.smallThumbnail
              : item.thumbnail
              ? item.thumbnail
              : item.tinyThumbnail
              ? item.tinyThumbnail
              : require('@/assets/images/book_cover_unavailable.png')
          }
          alt={item.title}
        ></Image>
      }
    </div>
  );
}
