import { ForwardIcon } from "../common/Icons";

export function ForwardButton({ player, amount = 10 }) {
  return (
    <button
      type="button"
      className="group relative rounded-full focus:outline-none"
      onClick={() => player.seekBy(amount)}
      aria-label={`Fast-forward ${amount} seconds`}
    >
      <div className="absolute -inset-4 -left-2 md:hidden" />
      <ForwardIcon className="h-6 w-6 stroke-teal-600 group-hover:stroke-teal-700" />
    </button>
  );
}
