import { useEffect } from 'react';

export function AuthLayout({ children }) {
  useEffect(() => {
    document.querySelector('html').classList.add('h-full');
    document.querySelector('html').classList.add('bg-white');

    document.body.classList.add('h-full');
  });

  return (
    <>
      <main className="h-full">{children}</main>
    </>
  );
}
