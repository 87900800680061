import { useEffect, useState } from 'react';

export default function useKeyPress(targetKey) {
  const [keyPressed, setKeyPressed] = useState(false);
  const [keyReleased, setKeyReleased] = useState(false);

  const downHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyReleased(false);
      setKeyPressed(true);
    }
  };

  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyReleased(true);
      setKeyPressed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []);

  return [keyPressed, keyReleased];
}
