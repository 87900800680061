import { SearchIcon } from '@heroicons/react/outline';
import { useState } from 'react';
import CommandPallette from './CommandPalette';

export default function SearchBar({ query, setQuery }) {
  const [showCommandPallette, setShowCommandPallette] = useState(Boolean(query));
  const [localQuery, setLocalQuery] = useState(query);

  const handleChange = (val) => {
    setShowCommandPallette(Boolean(val));
    setQuery(val);
    setLocalQuery(val);
  };
  return (
    <>
      <CommandPallette
        show={showCommandPallette}
        setShow={setShowCommandPallette}
        query={query}
        handleChange={handleChange}
      ></CommandPallette>

      <div className={query && query.length ? 'opacity-20' : 'w-full'}>
        <label
          htmlFor="search"
          className="sr-only text-sm font-medium text-gray-900 dark:text-white"
        >
          Search
        </label>
        <div className="relative">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 ">
            <SearchIcon className="h-5 w-5 text-gray-500 dark:text-gray-400"></SearchIcon>
          </div>
          <input
            type="search"
            value={localQuery}
            onChange={(e) => handleChange(e.target.value)}
            onClick={() => setShowCommandPallette(true)}
            className="text-md block w-full rounded-full border border-gray-200 bg-gray-50 pl-8 text-gray-800 text-gray-500 transition duration-500 ease-in-out focus:border-yellow-400 focus:ring-yellow-400 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-yellow-500 dark:focus:ring-yellow-500 sm:p-1 md:p-2 md:pl-8 lg:p-3 lg:pl-10"
            placeholder="Znajdź książkę po tytule, ISBN lub autorze."
          />
        </div>
      </div>
    </>
  );
}
