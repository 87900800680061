function extractStrings(obj: any): string[] {
  if (typeof obj === 'object') {
    if (Array.isArray(obj)) {
      const extractedStrings: string[] = [];
      for (const item of obj) {
        extractedStrings.push(...extractStrings(item));
      }
      return extractedStrings;
    } else {
      const extractedStrings: string[] = [];
      for (const key of Object.keys(obj)) {
        extractedStrings.push(...extractStrings(obj[key]));
      }
      return extractedStrings;
    }
  } else if (typeof obj === 'string') {
    return [obj];
  } else {
    return [];
  }
}
export default extractStrings
