import { SearchIcon } from '@heroicons/react/outline';
import { useCallback, useEffect, useState } from 'react';
import DocsCommandPallette from './DocsCommandPallette';

export function Search() {
  let [isOpen, setIsOpen] = useState(false);
  let [modifierKey, setModifierKey] = useState();

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, [isOpen]);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, [isOpen]);

  useEffect(() => {
    setModifierKey(/(Mac|iPhone|iPod|iPad)/i.test(navigator.platform) ? '⌘' : 'Ctrl ');
  }, []);

  return (
    <>
      <button
        type="button"
        className="group flex h-6 w-full items-center justify-center rounded-full sm:justify-start  md:h-auto md:flex-none md:py-2.5 md:pl-4 md:pr-3.5 md:text-sm md:ring-1 md:ring-slate-200 md:hover:ring-slate-300 dark:md:bg-slate-800/75 dark:md:ring-inset dark:md:ring-white/5 dark:md:hover:bg-slate-700/40 dark:md:hover:ring-slate-500"
        onClick={onOpen}
      >
        <SearchIcon className="h-5 w-5 text-gray-500 dark:text-gray-400"></SearchIcon>{' '}
        <span className="sr-only md:not-sr-only md:ml-2 md:text-slate-500 md:dark:text-slate-400">
          Wyszukaj w dokumentacji
        </span>
        {modifierKey && (
          <kbd className="ml-auto hidden font-medium text-slate-400 dark:text-slate-500 md:block">
            <kbd className="font-sans">{modifierKey}</kbd>
            <kbd className="font-sans">K</kbd>
          </kbd>
        )}
      </button>
      <DocsCommandPallette show={isOpen} setShow={setIsOpen} />
    </>
  );
}
