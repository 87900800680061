import { AlgoliaSVG } from '@/assets/svg';
import { Combobox, Dialog, Transition } from '@headlessui/react';
import { ReceiptRefundIcon, SearchIcon, XIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { Fragment, useEffect, useState } from 'react';
import Loader from '../../common/Loader';
import { FormatSearchResult } from './FormatSearchResult';

const algoliaConfig = {
  appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  apiKey: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY,
};

const ALGOLIA_SEARCH_URL = `https://${algoliaConfig.appId.toLowerCase()}-dsn.algolia.net/1/indexes/*/queries?x-algolia-agent=Algolia%20for%20JavaScript%20(4.17.0)%3B%20Browser%20(lite)%3B%20docsearch%20(3.3.4)%3B%20docsearch-react%20(3.3.4)&x-algolia-api-key=${
  algoliaConfig.apiKey
}&x-algolia-application-id=${algoliaConfig.appId}`;

export default function DocsCommandPallette({ show, setShow }) {
  const [query, setQuery] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchFinished, setSearchFinished] = useState(false);

  const afterleave = () => {
    setSearchResults([]);
    handleChange('');
    setSearchFinished(false);
    setSearchResults([]);
    setShow(false);
  };

  const handleChange = (val) => {
    setQuery(val);
  };

  const handleNavigateToDoc = () => {
    afterleave();
  };

  useEffect(() => {
    // Debounce bounce effect
    if (query.length > 3) {
      setSearchResults([]);
      const getData = setTimeout(() => {
        setIsSearching(true);
        setSearchFinished(false);

        var myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');

        var raw = JSON.stringify({
          requests: [
            {
              query: query,
              indexName: 'youtalky.com_docs',
              params: '',
              attributesToRetrieve: '*', //https://www.algolia.com/doc/api-reference/api-parameters/attributesToRetrieve/
            },
          ],
        });

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow',
        };

        fetch(ALGOLIA_SEARCH_URL, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            setSearchResults(result.results?.length ? result.results[0].hits : []);
            setIsSearching(false);
            setSearchFinished(true);
          })
          .catch((error) => {
            console.log('error', error);
            setIsSearching(false);
            setSearchFinished(true);
          });
      }, 400);
      // Destroy the instance of the useEffect hook using return, followed by clearTimeout, every time it finishes.
      return () => clearTimeout(getData);
    }
  }, [query]);

  return (
    <Transition.Root show={show} as={Fragment} afterLeave={afterleave} appear>
      <Dialog as="div" className="relative z-30" onClose={() => setShow(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-50"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-50"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="shadow-2xl mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox>
                <div className="relative">
                  <SearchIcon
                    className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <Combobox.Input
                    id="overview-search"
                    role="toolbar"
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
                    placeholder="Znajdź w dokumentacji."
                    onChange={(e) => handleChange(e.target.value)}
                    value={query}
                  />

                  <div
                    onClick={() => setShow(false)}
                    className="pointer-events-auto absolute inset-y-0 right-0 z-10 flex items-center pr-3"
                  >
                    <XIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                </div>

                {searchResults.length > 0 && !isSearching && (
                  <Combobox.Options static className="max-h-96 scroll-py-3 overflow-y-auto p-3">
                    {searchResults.map((item) => (
                      <Combobox.Option
                        id={`doc-${item.objectID}`}
                        key={item.objectID}
                        role="feed"
                        className={({ active }) =>
                          clsx(
                            'flex cursor-default select-none rounded-xl p-3',
                            active && 'bg-gray-100'
                          )
                        }
                      >
                        {({ active }) => (
                          <FormatSearchResult
                            item={item}
                            active={active}
                            onClick={handleNavigateToDoc}
                          />
                        )}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                )}

                {query != '' && isSearching && (
                  <div className="h-full py-14 px-6 text-center text-sm sm:px-14">
                    <Loader></Loader>
                  </div>
                )}
                {query != '' && searchResults.length === 0 && !isSearching && searchFinished && (
                  <div className="py-14 px-6 text-center text-sm sm:px-14">
                    <ReceiptRefundIcon
                      type="outline"
                      name="exclamation-circle"
                      className="mx-auto h-6 w-6 text-gray-400"
                    />
                    <p className="mt-2 text-gray-500">
                      Nie znaleziono nic w poradnikach z tą frazą kluczową. Spróbuj zmniejszyć
                      zapytanie.
                    </p>
                  </div>
                )}
                <div className="items-right flex justify-end bg-gray-50 py-2.5 px-4 text-xs text-gray-700">
                  <span className="mx-2">Search by</span> <AlgoliaSVG />
                </div>
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
