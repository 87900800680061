import Header from '@/components/Header';
import { useState } from 'react';

import { AudioPlayer } from '@/components/player/AudioPlayer';
import { useRouter } from 'next/router';

import AboutSection from '@/components/common/AboutSection';
import clsx from 'clsx';
import { useSearchParams } from 'next/navigation';

export function AudioLayout({ query, setQuery, bookisbn, children }) {

  const searchParams = useSearchParams();
  let startAt = searchParams.get('t')

  const [isReaderSelected, setIsReaderSelected] = useState(true);
  const router = useRouter();

  const isEpisodeOpened = router.query.episode;

  return (
    <>
      <Header query={query} setQuery={setQuery}></Header>
      {children}
      <footer className="hidden border-t border-slate-200 bg-slate-50 py-10 pb-40 sm:py-16 sm:pb-32 lg:block lg:hidden">
        <div className="mx-auto px-4 sm:px-6 md:max-w-2xl md:px-4">
          <AboutSection isOpen={isReaderSelected} />
        </div>
      </footer>
      <div
        className={clsx(
          'fixed inset-x-0 bottom-0 z-10',
          !isEpisodeOpened && 'lg:left-112 xl:left-120'
        )}
      >
        <AudioPlayer startAt={startAt} />
      </div>
    </>
  );
}
